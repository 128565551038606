import React from 'react'
import { NextSeo } from 'next-seo'
import Layout from '../components/layout'
import ContactButtons from '../components/contact-buttons'

import 'inter-ui/inter.css'

export default function Home({}) {
  return (
    <>
      <Layout home={true}>
        <NextSeo
          title="James Mugliston"
          titleTemplate="%s | Full-Stack Developer"
          description="A full-stack software developer who creates distributed, scalable systems using JavaScript technologies."
        />
        <section className="pt-3">
          <p>
            👋 Hi, I&apos;m James! I&apos;m a Full-Stack developer specialising
            in JavaScript, TypeScript and Node.js. I have extensive experience
            working with modern frameworks and libraries (including React,
            Angular, and Next.js) to develop secure, resilient, and scalable web
            applications.
          </p>
          <p>
            I&apos;m passionate about cloud technology and have worked with
            various platforms, including AWS, Azure and GCP, to deploy
            distributed serverless applications. Additionally, I believe in
            modern DevOps techniques, such as using continuous integration and
            infrastructure as code for automated deployments at scale.
          </p>
          <p>
            Above all, I&apos;m curious and always looking to develop and learn
            new skills and technologies!
          </p>
          <div className="mt-10 mb-10">
            <ContactButtons />
          </div>
        </section>
      </Layout>
    </>
  )
}
