import Head from 'next/head'
import Image from 'next/image'
import Link from 'next/link'
import { usePathname } from 'next/navigation'
import { NextSeo } from 'next-seo'

import Tabbar from './tabbar'
import Footer from './footer'
import ThemeToggler from './theme'

export const siteName = 'James Mugliston'

// Use a really basic image loader so we can export the site
export const imageLoader = ({ src }) => src

export default function Layout({ children, home }) {
  const pathname = usePathname()

  const navData = [
    { name: 'Home', link: '/' },
    { name: 'Blog', link: '/blog' },
    { name: 'Projects', link: '/projects' },
    { name: 'CV', link: '/cv' },
  ]

  return (
    <>
      <div className="text-center mt-8">
        <ThemeToggler />
      </div>
      <Tabbar navigationData={navData} />{' '}
      <div className="my-3 px-4 w-100 sm:max-w-xl md:w-3/4 md:max-w-3xl mx-auto">
        <Head>
          <link rel="icon" href="/favicon.ico" />
        </Head>
        <NextSeo
          title={siteName}
          description="A personal blog website."
          openGraph={{
            siteName: siteName,
            title: siteName,
            description: 'A personal blog website.',
            images: [
              {
                url: '/images/profile.webp',
                width: 144,
                height: 144,
                alt: siteName,
              },
            ],
          }}
        />
        {!pathname.includes('/blog/') && (
          <header className="space-y-2 mt-5 text-center">
            {home && (
              <>
                <Image
                  unoptimized
                  loader={imageLoader}
                  priority
                  src="/images/profile.webp"
                  className="block mx-auto rounded-full sm:shrink-0"
                  height={144}
                  width={144}
                  alt={siteName}
                />
                <h1 className="text-xl font-light py-2">{siteName}</h1>
              </>
            )}
          </header>
        )}
        <main>{children}</main>
        <Footer />
      </div>
    </>
  )
}
