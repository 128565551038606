import { useCallback } from 'react'
import Link from 'next/link'
import { useRouter } from 'next/router'
import classNames from 'classnames'
import { AiFillHome } from 'react-icons/ai'
import { BsFillPersonFill, BsChatLeftQuote } from 'react-icons/bs'
import { BiGitRepoForked } from 'react-icons/bi'
import { MdWorkOutline } from 'react-icons/md'
import styles from '../styles/tabbar.module.css'

export default function Tabbar({ navigationData = [] }) {
  const router = useRouter()

  const getTabIcon = useCallback((item) => {
    switch (item) {
      case 'Home':
        return <AiFillHome />
      case 'Blog':
        return <BsChatLeftQuote />
      case 'Projects':
        return <BiGitRepoForked />
      case 'CV':
        return <MdWorkOutline />
      case 'Contact':
        return <BsFillPersonFill />
    }
  }, [])

  return (
    <nav className={styles.tabbar}>
      {navigationData.map((item, index) => (
        <span
          key={index}
          className={classNames([
            styles.tabItem,
            `/${router.pathname.split('/')[1]}` === item.link &&
              styles.tabItemActive,
          ])}
        >
          <Link href={item.link} aria-label={item.name} passHref>
            <span className={styles.icon}>{getTabIcon(item.name)}</span>
          </Link>
        </span>
      ))}
    </nav>
  )
}
