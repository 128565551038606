import { FaRegEnvelope, FaGithub, FaLinkedin } from 'react-icons/fa'

export default function ContactButtons({ iconSize = 30 }) {
  return (
    <div className="flex flex-row flex-wrap justify-center space-x-16">
      <div>
        <a
          rel="noreferrer"
          target="_blank"
          href="&#109;&#97;&#105;&#108;&#116;&#111;&#58;%6A%61%6D%65%73%2E%6D%75%67%6C%69%73%74%6F%6E%40%67%6D%61%69%6C%2E%63%6F%6D"
          aria-label="Email"
        >
          <FaRegEnvelope size={iconSize} />
        </a>
      </div>
      <div>
        <a
          rel="noreferrer"
          target="_blank"
          href="https://github.com/atheius"
          aria-label="Github"
        >
          <FaGithub size={iconSize} />
        </a>
      </div>
      <div>
        <a
          rel="noreferrer"
          target="_blank"
          href="https://www.linkedin.com/in/jmugliston/"
          aria-label="LinkedIn"
        >
          <FaLinkedin size={iconSize} />
        </a>
      </div>
    </div>
  )
}
